import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Compounds 
import { UserProvider } from './Components/04_SSoTs/UserContext';
import NavbarX from './Components/02_Clusters/NavbarX';
import Login from './Components/05_View/Login';
import CartelleCliniche from './Components/05_View/CartelleCliniche';
// import Djtemplate from './Components/View/Djtemplate';
// import FECV from './Components/View/FECV';
// import MapView from './Components/View/MapView';
import GoogleAuthRedirect from './Components/05_View/GoogleAuthRedirect';
import ConvertitoreXML from './Components/05_View/ConvertitoreXML';
import UserList from "./Components/05_View/UsersList";
import BasicModal from "./Components/00_Elements/BasicModal";
import Home from "./Components/05_View/Home";
import Strumenti from "./Components/05_View/Strumenti";
// import TestModal from "./Components/00_Elements/TestModal";

function App() {
    return (
        <UserProvider>
            <Router>
                {/* Full-screen container */}
                <div className="Desktop relative min-h-screen bg-green-900">
                    {/* Navbar */}
                    <NavbarX />
                    {/* Content Section */}
                    <div className="Content w-full pb-14">
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/pazienti" element={<CartelleCliniche />} />
                            {/* <Route path="/FECV" element={<FECV />} /> */}
                            {/* <Route path="/Map" element={<MapView />} /> */}
                            <Route path="/GoogleAuthRedirect" element={<GoogleAuthRedirect />} />
                            <Route path="/convertitoreXML" element={<ConvertitoreXML />} />
                            {/* <Route path="/djtemplate" element={<Djtemplate />} /> */}
                            <Route path="/users" element={<UserList />} />
                            <Route path="/" element={<Home />} />
                            <Route path="/strumenti" element={<Strumenti />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        </UserProvider>
    );
}

export default App;


