// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { Listbox as HeadlessListbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
// import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
// import clsx from 'clsx';

// const Listbox = ({
// 	options = ['Option 1', 'Option 2', 'Option 3'], // Default options
// 	selected, // Selected option
// 	onChange = () => {} // Default onChange function
// }) => {
// 	const [internalSelected, setInternalSelected] = useState(selected || options[0]);

// 	useEffect(() => {
// 		if (selected) {
// 			setInternalSelected(selected);
// 		}
// 	}, [selected]);

// 	const handleChange = (value) => {
// 		setInternalSelected(value);
// 		if (onChange) {
// 			onChange(value);
// 		}
// 	};

// 	return (
// 		<HeadlessListbox value={internalSelected} onChange={handleChange}>
// 			<div className="relative mt-2">
// 				<ListboxButton
// 					className={clsx(
// 						'relative block w-full rounded-t-xl rounded-b-none bg-gray-100 border-black py-1.5 pr-8 pl-3 text-left text-sm/6 text-zinc-950',
// 						'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-zink-950'
// 					)}
// 				>
// 					<span className="block truncate">{internalSelected}</span>
// 					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
// 						<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
// 					</span>
// 				</ListboxButton>
// 				<ListboxOptions
// 					className={clsx(
// 						'absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
// 						'transition duration-100 ease-in-out data-[leave]:data-[closed]:opacity-0'
// 					)}
// 				>
// 					{options.map((option, index) => (
// 						<ListboxOption
// 							key={index}
// 							value={option}
// 							className={({ active }) =>
// 								`relative cursor-default select-none py-2 pl-10 pr-4 ${
// 									active ? 'bg-indigo-600 text-white' : 'text-gray-900'
// 								}`
// 							}
// 						>
// 							{({ selected: isSelected }) => (
// 								<>
// 									<span className={`block truncate ${isSelected ? 'font-medium' : 'font-normal'}`}>
// 										{option}
// 									</span>
// 									{isSelected ? (
// 										<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
// 											<CheckIcon className="h-5 w-5" aria-hidden="true" />
// 										</span>
// 									) : null}
// 								</>
// 							)}
// 						</ListboxOption>
// 					))}
// 				</ListboxOptions>
// 			</div>
// 		</HeadlessListbox>
// 	);
// };

// Listbox.propTypes = {
// 	options: PropTypes.arrayOf(PropTypes.string),
// 	selected: PropTypes.string,
// 	onChange: PropTypes.func,
// };

// export default Listbox;


// filepath: /home/docker/ar/srcs/data/dev/alfareverie/src/Components/00_Elements/Listbox.jsx
// filepath: /home/docker/ar/srcs/data/dev/alfareverie/src/Components/00_Elements/Listbox.jsx
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Listbox as HeadlessListbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

const CustomListbox = ({
  options = ['Option 1', 'Option 2', 'Option 3'], // Default options
  selected, // Selected option
  onChange = () => {}, // Default onChange function
  buttonClassName, // Custom class for ListboxButton
  optionsClassName, // Custom class for ListboxOptions
  optionClassName, // Custom class for ListboxOption
  selectedOptionClassName, // Custom class for selected ListboxOption
  iconClassName, // Custom class for ChevronDownIcon
}) => {
  const [internalSelected, setInternalSelected] = useState(selected || options[0]);

  useEffect(() => {
    if (selected) {
      setInternalSelected(selected);
    }
  }, [selected]);

  const handleChange = (value) => {
    setInternalSelected(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="relative mt-2">
      <HeadlessListbox value={internalSelected} onChange={handleChange}>
        <div className="relative">
          <ListboxButton
            className={clsx(
              'relative block w-full rounded-t-xl rounded-b-none py-1.5 pr-8 pl-3 text-left text-sm/6 text-zinc-950',
              'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-zink-950',
              buttonClassName
            )}
          >
            <span className="block truncate">{internalSelected}</span>
            <span className={clsx('pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2', iconClassName)}>
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>
          <ListboxOptions
            className={clsx(
              'absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
              'transition duration-100 ease-in-out data-[leave]:data-[closed]:opacity-0',
              optionsClassName
            )}
          >
            {options.map((option, index) => (
              <ListboxOption
                key={index}
                value={option}
                className={({ active }) =>
                  clsx(
                    'relative cursor-default select-none py-2 pl-10 pr-4',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    optionClassName
                  )
                }
              >
                {({ selected: isSelected }) => (
                  <>
                    <span className={clsx('block truncate', isSelected ? 'font-medium' : 'font-normal', selectedOptionClassName)}>
                      {option}
                    </span>
                    {isSelected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </HeadlessListbox>
    </div>
  );
};

CustomListbox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.string,
  onChange: PropTypes.func,
  buttonClassName: PropTypes.string,
  optionsClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  selectedOptionClassName: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default CustomListbox;