// import ReveLogo from '../../Assets/logoreverie.png';

// export default function NavbarXBottom() {
//     return (
//         <>
//             <div className="secondary-navbar bg-white">
//                 <div className="secondary-wrapper mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8">
//                     <div className="secondary-content flex items-center justify-between">
//                     <img src={ReveLogo} alt="Reve Logo" className="brand-logo" />
//                     <span className="brand-name text-black text-xl font-medium font-waterBrush tracking-wide">Alfa Reverie</span>

//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

import React from 'react';
import ReveLogo from '../../Assets/logo.svg';
import './NavbarXBottom.css'; // Import the CSS file
import Listbox from '../00_Elements/Listbox';

export default function NavbarXBottom() {
    const roles = ['admin', 'privileged', 'user', 'guest']; // Define roles array

    return (
        <>
            <div className="secondary-navbar bg-white shadow-lg shadow-black-50">
                <div className="secondary-wrapper mx-auto max-w-7xl py-2 px-4 sm:px-6 lg:px-8">
                    <div className="secondary-content flex items-center justify-between">
                        <div className="brand-menu flex items-center flex-shrink-0">
                            <img src={ReveLogo} alt="Reve Logo" className="brand-logo" />
                            <span className="brand-name text-black font-medium font-arizonia tracking-wide ml-2">Alfa Reverie</span>
                        </div>
                        <div className="context-menu flex items-center space-x-4 z-10">
                            <Listbox options={roles} />
                            <Listbox options={roles} />
                            <Listbox options={roles} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}