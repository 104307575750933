// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import BasicModal from '../Element/BasicModal'; // Adjust the import path as necessary
// import Listbox from '../Element/Listbox'; // Adjust the import path as necessary
// import InputField from '../Element/InputField'; // Adjust the import path as necessary

// const fetchCsrfToken = async () => {
//     await axios.get('/api/getcsrf/');
//     return Cookies.get('csrftoken');
// };

// const UsersList = () => {
//     const [users, setUsers] = useState([]);
//     const [selectedUser, setSelectedUser] = useState(null);
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [selectedRole, setSelectedRole] = useState('');
//     const roles = ['admin', 'privileged', 'user', 'guest']; // Define roles array

//     useEffect(() => {
//         const getUsers = async () => {
//             const csrfToken = await fetchCsrfToken();
//             axios.get('/api/users/', {
//                 headers: {
//                     'X-CSRFToken': csrfToken
//                 },
//                 withCredentials: true
//             })
//                 .then(response => {
//                     if (Array.isArray(response.data)) {
//                         setUsers(response.data);
//                     } else {
//                         console.error('API response is not an array:', response.data);
//                     }
//                 })
//                 .catch(error => {
//                     console.error('There was an error fetching the users!', error);
//                 });
//         };

//         getUsers();
//     }, []);

//     const handleEditClick = (user) => {
//         setSelectedUser(user);
//         setSelectedRole(user.role); // Set the initial role in the listbox
//         setIsModalOpen(true);
//     };

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setSelectedUser(prevState => ({
//             ...prevState,
//             [name]: value
//         }));
//     };

//     const handleSaveChanges = async () => {
//         if (selectedUser) {
//             const csrfToken = await fetchCsrfToken();
//             axios.put(`/api/users/${selectedUser.id}/`, {
//                 username: selectedUser.username,
//                 email: selectedUser.email,
//                 role: selectedRole
//             }, {
//                 headers: {
//                     'X-CSRFToken': csrfToken
//                 },
//                 withCredentials: true
//             })
//                 .then(response => {
//                     setIsModalOpen(false);
//                     // Update the users list with the modified user
//                     setUsers(users.map(user => user.id === selectedUser.id ? response.data : user));
//                 })
//                 .catch(error => {
//                     console.error('There was an error updating the user!', error);
//                 });
//         }
//     };

//     return (
//         <>
//             <div className='Utenti bg-white border-black container mx-auto flex flex-col items-center w-full sm:w-[75%] md:w-[65%] lg:w-[60%] min-w-[360px] justify-center mt-5 rounded-b-xl'>
//                 <div className="NewUtenti overflow-x-auto w-full">
//                     <table className="table w-full">
//                         {/* head */}
//                         <thead>
//                             <tr>
//                                 <th>
//                                     <label>
//                                         <input type="checkbox" className="checkbox" />
//                                     </label>
//                                 </th>
//                                 <th>Username</th>
//                                 <th>Email</th>
//                                 <th>Role</th>
//                                 <th></th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {users.map(user => (
//                                 <tr key={user.id} className="align-middle">
//                                     <th className="align-middle">
//                                         <label>
//                                             <input type="checkbox" className="checkbox" />
//                                         </label>
//                                     </th>
//                                     <td className="align-middle">{user.username}</td>
//                                     <td className="align-middle">{user.email}</td>
//                                     <td className="align-middle">{user.role}</td>
//                                     <th className="align-middle">
//                                         <button className="btn btn-ghost btn-xs" onClick={() => handleEditClick(user)}>edit</button>
//                                     </th>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>

//                 <BasicModal
//                     isOpen={isModalOpen}
//                     onClose={() => setIsModalOpen(false)}
//                     title="Edit User"
//                     actions={[
//                         { label: 'Save Changes', onClick: handleSaveChanges }
//                     ]}
//                 >
//                     {selectedUser && (
//                         <div className="py-4">
//                             <InputField
//                                 size="default"
//                                 placeholder="Username"
//                                 value={selectedUser.username}
//                                 onChange={handleInputChange}
//                                 name="username"
//                             />
//                             <InputField
//                                 size="default"
//                                 placeholder="Email"
//                                 value={selectedUser.email}
//                                 onChange={handleInputChange}
//                                 name="email"
//                             />
//                             <Listbox options={roles} selected={selectedRole} onChange={setSelectedRole} />
//                         </div>
//                     )}
//                 </BasicModal>
//             </div>
//         </>
//     );
// };

// export default UsersList;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import BasicModal from '../00_Elements/BasicModal'; // Adjust the import path as necessary
import Listbox from '../00_Elements/Listbox'; // Adjust the import path as necessary
import InputField from '../00_Elements/InputField'; // Adjust the import path as necessary

const fetchCsrfToken = async () => {
    await axios.get('/api/getcsrf/');
    return Cookies.get('csrftoken');
};

const UsersList = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State for error message
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false); // State for error modal
    const roles = ['admin', 'privileged', 'user', 'guest']; // Define roles array

    useEffect(() => {
        const getUsers = async () => {
            const csrfToken = await fetchCsrfToken();
            axios.get('/api/users/', {
                headers: {
                    'X-CSRFToken': csrfToken
                },
                withCredentials: true
            })
                .then(response => {
                    if (Array.isArray(response.data)) {
                        setUsers(response.data);
                    } else {
                        setErrorMessage(typeof response.data === 'string' ? response.data : JSON.stringify(response.data));
                        setIsErrorModalOpen(true);
                    }
                })
                .catch(error => {
                    setErrorMessage('There was an error fetching the users: ' + error.message);
                    setIsErrorModalOpen(true);
                });
        };

        getUsers();
    }, []);

    const handleEditClick = (user) => {
        setSelectedUser(user);
        setSelectedRole(user.role); // Set the initial role in the listbox
        setIsModalOpen(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSaveChanges = async () => {
        if (selectedUser) {
            const csrfToken = await fetchCsrfToken();
            axios.put(`/api/users/${selectedUser.id}/`, {
                username: selectedUser.username,
                email: selectedUser.email,
                role: selectedRole
            }, {
                headers: {
                    'X-CSRFToken': csrfToken
                },
                withCredentials: true
            })
                .then(response => {
                    setIsModalOpen(false);
                    // Update the users list with the modified user
                    setUsers(users.map(user => user.id === selectedUser.id ? response.data : user));
                })
                .catch(error => {
                    setErrorMessage('There was an error updating the user: ' + error.message);
                    setIsErrorModalOpen(true);
                });
        }
    };

    return (
        <>
            <div className='Utenti bg-white border-black container mx-auto flex flex-col items-center w-full sm:w-[75%] md:w-[65%] lg:w-[60%] min-w-[360px] justify-center mt-5 rounded-b-xl'>
                <div className="NewUtenti overflow-x-auto w-full">
                    <table className="table w-full">
                        {/* head */}
                        <thead>
                            <tr>
                                <th>
                                    <label>
                                        <input type="checkbox" className="checkbox" />
                                    </label>
                                </th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => (
                                <tr key={user.id} className="align-middle">
                                    <th className="align-middle">
                                        <label>
                                            <input type="checkbox" className="checkbox" />
                                        </label>
                                    </th>
                                    <td className="align-middle">{user.username}</td>
                                    <td className="align-middle">{user.email}</td>
                                    <td className="align-middle">{user.role}</td>
                                    <th className="align-middle">
                                        <button className="btn btn-ghost btn-xs" onClick={() => handleEditClick(user)}>edit</button>
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <BasicModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    title="Edit User"
                    actions={[
                        { label: 'Save Changes', onClick: handleSaveChanges }
                    ]}
                >
                    {selectedUser && (
                        <div className="py-4">
                            <InputField
                                size="default"
                                placeholder="Username"
                                value={selectedUser.username}
                                onChange={handleInputChange}
                                name="username"
                            />
                            <InputField
                                size="default"
                                placeholder="Email"
                                value={selectedUser.email}
                                onChange={handleInputChange}
                                name="email"
                            />
                            <Listbox options={roles} selected={selectedRole} onChange={setSelectedRole} />
                        </div>
                    )}
                </BasicModal>

                {/* Error Modal */}
                <BasicModal
                    isOpen={isErrorModalOpen}
                    onClose={() => setIsErrorModalOpen(false)}
                    title="Error"
                    actions={[
                        { label: 'Close', onClick: () => setIsErrorModalOpen(false) }
                    ]}
                >
                    <div className="py-4" dangerouslySetInnerHTML={{ __html: errorMessage }} />
                </BasicModal>
            </div>
        </>
    );
};

export default UsersList;